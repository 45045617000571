import { Meta, Title } from '@solidjs/meta';
import { Button, Carousel, Container, Heading, Link, Page, Picture, Section } from '@troon/ui';
import { For, Show, Suspense, createMemo, onMount } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { createAsync, useNavigate, useSearchParams } from '@solidjs/router';
import { Hero } from '../../../../components/hero/photo';
import { TeeTimeSearchFields } from '../../../../components/tee-time-search';
import { timeframes } from '../../../../modules/date-formatting';
import { UpsellAccess } from '../../../../components/upsells/access';
import { FacilityCard } from '../../../../components/facility/card';
import { PictureSection } from '../../../../components/layouts/picture-section';
import { gql } from '../../../../graphql';
import { cachedQuery } from '../../../../graphql/cached-get';
import { getConfigValue } from '../../../../modules/config';
import type { FacilityLandingFragment } from '../../../../graphql';
import type { RouteDefinition } from '@solidjs/router';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	location: string;
	title: string;
	image: string;
	description?: string;
	slugs: Record<string, string>;
}>;

export default function TroonGolfLandingPages(props: Props) {
	const navigate = useNavigate();
	let formRef: HTMLFormElement;
	const facilities = createAsync(() => getFacilities({ slugs: Object.keys(props.slugs) }), { deferStream: true });
	const [, setSearchParams] = useSearchParams();

	const heroImage = createMemo(() => {
		let url: string | undefined;
		const fac = facilities()?.facilities.facilities;
		if (!fac) {
			return null;
		}
		while (!url) {
			url = fac[Math.floor(Math.random() * fac.length)]?.metadata?.hero?.url;
		}
		return url;
	});

	onMount(() => {
		setSearchParams({ utm_campaign: 'coop-site', utm_source: props.location.toLowerCase() });
	});

	return (
		<Suspense>
			<Show when={heroImage() && facilities()?.facilities.facilities}>
				{(facilities) => (
					<>
						<NoHydration>
							<Title>{props.title}</Title>
							<Meta name="description" content={props.description ?? ''} />
							<Hero src={heroImage()!} fullWidth>
								<Heading as="h1" class="w-full max-w-xl px-8 text-center text-white">
									<Picture
										// TODO: need logos for varying locations
										src={`https://${getConfigValue('MAP_HOST')}/assets/logos/${props.image}`}
										alt={`Troon Golf ${props.location}`}
										crop="center"
										mode="contain"
										class="mb-16 w-full"
										sizes={[
											[300, 100],
											[600, 200],
										]}
									/>
									<span class="sr-only">{props.title}</span>
								</Heading>
							</Hero>
						</NoHydration>

						<Container class="relative -top-24 z-30 -mb-24">
							<form
								method="get"
								action="/tee-times"
								ref={formRef!}
								class="flex flex-row flex-wrap items-stretch rounded bg-white p-4 shadow-lg xl:flex-nowrap"
								onSubmit={(e) => {
									e.preventDefault();
									const data = new FormData(formRef);
									const [startAt, endAt] = timeframes[data.get('time') as keyof typeof timeframes] ?? [0, 24];
									if (startAt && endAt) {
										data.set('startAt', `${startAt}`);
										data.set('endAt', `${endAt}`);
									}
									data.delete('time');
									data.set('query', '');
									data.set(
										'facilities',
										Object.values(facilities())
											.map((f) => f.slug)
											.join(','),
									);
									const params = new URLSearchParams(Object.fromEntries(data) as Record<string, string>);
									navigate(`/tee-times/?${params.toString()}`);
								}}
							>
								<TeeTimeSearchFields hideQueryInput />
							</form>
						</Container>

						<Page>
							<Section>
								<Container>
									<PictureSection src={heroImage()!}>
										{props.children}
										<div>
											<Button as={Link} href="#courses" appearance="secondary" class="size-fit">
												Explore courses
											</Button>
										</div>
									</PictureSection>
								</Container>
							</Section>

							<div class="border-y border-y-neutral">
								<Carousel itemClass="w-fit lg:w-fit min-w-fit border-s border-neutral last:border-e">
									<For each={facilities() as Array<FacilityLandingFragment>}>
										{(facility) => (
											<Show when={facility.metadata?.logo?.url}>
												{(url) => (
													<Carousel.Item>
														<Link class="block px-16 py-4" href={`/course/${facility.slug}`}>
															<Picture
																class="w-fit"
																src={url()}
																sizes={[[200, 200]]}
																mode="contain"
																crop="center"
																alt={facility.name}
															/>
														</Link>
													</Carousel.Item>
												)}
											</Show>
										)}
									</For>
								</Carousel>
							</div>

							<Container>
								<Heading id="courses" as="h2">
									Explore {props.location} courses
								</Heading>
								<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 md:gap-8">
									<For
										each={
											(facilities() as Array<FacilityLandingFragment>).sort((a, b) =>
												compare(a.name, b.name),
											) as Array<FacilityLandingFragment> as Array<FacilityLandingFragment>
										}
									>
										{(facility) => (
											<FacilityCard disableFavorites facility={facility} phone={props.slugs[facility.slug]} />
										)}
									</For>
								</div>
							</Container>

							<Container>
								<UpsellAccess />
							</Container>
						</Page>
					</>
				)}
			</Show>
		</Suspense>
	);
}

const compare = new Intl.Collator('en').compare;

const query = gql(`query landingPageFacilities($slugs: [String!]) {
	facilities: facilitiesV3(idOrSlugs: $slugs) {
    facilities {
      ...FacilityLanding
    }
  }
}
fragment FacilityLanding on Facility {
  slug
  name
  ...FacilityCard
  metadata {
    hero { url }
    logo { url }
    address {
      city
      state
    }
  }
}`);

const getFacilities = cachedQuery(query);

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
